import request from 'utils/request';
const api = {
  COUNTRY_PAGE: '/api/admin/v3/country/page',
  COUNTRY_LIST: '/api/admin/v3/country/list',
  COUNTRY_DETAILS: '/api/admin/v3/country/get',
  COUNTRY_ADD: '/api/admin/v3/country/add',
  COUNTRY_UPDATE: '/api/admin/v3/country/update',
  COUNTRY_DEL: '/api/admin/v3/country/delete',
};

// 分页
export function fetchCountryPage(query) {
  return request({
    url: api.COUNTRY_PAGE,
    method: 'get',
    params: query,
  });
}

// 列表
export function fetchCountryList(query) {
  return request({
    url: api.COUNTRY_LIST,
    method: 'get',
    params: query,
  });
}

// 详情
export function fetchCountryDetails(query) {
  return request({
    url: api.COUNTRY_DETAILS,
    method: 'get',
    params: query,
  });
}

// 添加
export function handleCountryAdd(data) {
  return request({
    url: api.COUNTRY_ADD,
    method: 'post',
    data,
  });
}

// 更新
export function handleCountryUpdate(data) {
  return request({
    url: api.COUNTRY_UPDATE,
    method: 'post',
    data,
  });
}

// 删除
export function handleCountryDel(query) {
  return request({
    url: api.COUNTRY_DEL,
    method: 'get',
    params: query,
  });
}
